import React, { forwardRef, useImperativeHandle, useRef, useState, useCallback } from 'react';
import { mergeProps } from '../../utils/with-default-props';
import { renderToContainer } from '../../utils/render-to-container';
import Mask from '../mask';
import SafeArea from '../safe-area';
import { Slide } from './slide';
import { Slides } from './slides';
import classNames from 'classnames';
const classPrefix = `adm-image-viewer`;
const defaultProps = {
  maxZoom: 3,
  getContainer: null,
  visible: false
};
export const ImageViewer = p => {
  var _a, _b, _c;
  const props = mergeProps(defaultProps, p);
  const node = React.createElement(Mask, {
    visible: props.visible,
    disableBodyScroll: false,
    opacity: 'thick',
    afterClose: props.afterClose,
    destroyOnClose: true,
    className: (_a = props === null || props === void 0 ? void 0 : props.classNames) === null || _a === void 0 ? void 0 : _a.mask
  }, React.createElement("div", {
    className: classNames(`${classPrefix}-content`, (_b = props === null || props === void 0 ? void 0 : props.classNames) === null || _b === void 0 ? void 0 : _b.body)
  }, props.image && React.createElement(Slide, {
    image: props.image,
    onTap: props.onClose,
    maxZoom: props.maxZoom
  })), props.image && React.createElement("div", {
    className: `${classPrefix}-footer`
  }, (_c = props.renderFooter) === null || _c === void 0 ? void 0 : _c.call(props, props.image), React.createElement(SafeArea, {
    position: 'bottom'
  })));
  return renderToContainer(props.getContainer, node);
};
const multiDefaultProps = Object.assign(Object.assign({}, defaultProps), {
  defaultIndex: 0
});
export const MultiImageViewer = forwardRef((p, ref) => {
  var _a, _b, _c;
  const props = mergeProps(multiDefaultProps, p);
  const [index, setIndex] = useState(props.defaultIndex);
  const slidesRef = useRef(null);
  useImperativeHandle(ref, () => ({
    swipeTo: (index, immediate) => {
      var _a;
      setIndex(index);
      (_a = slidesRef.current) === null || _a === void 0 ? void 0 : _a.swipeTo(index, immediate);
    }
  }));
  const onSlideChange = useCallback(newIndex => {
    var _a;
    if (newIndex === index) return;
    setIndex(newIndex);
    (_a = props.onIndexChange) === null || _a === void 0 ? void 0 : _a.call(props, newIndex);
  }, [props.onIndexChange, index]);
  const node = React.createElement(Mask, {
    visible: props.visible,
    disableBodyScroll: false,
    opacity: 'thick',
    afterClose: props.afterClose,
    destroyOnClose: true,
    className: (_a = props === null || props === void 0 ? void 0 : props.classNames) === null || _a === void 0 ? void 0 : _a.mask
  }, React.createElement("div", {
    className: classNames(`${classPrefix}-content`, (_b = props === null || props === void 0 ? void 0 : props.classNames) === null || _b === void 0 ? void 0 : _b.body)
  }, props.images && React.createElement(Slides, {
    ref: slidesRef,
    defaultIndex: index,
    onIndexChange: onSlideChange,
    images: props.images,
    onTap: props.onClose,
    maxZoom: props.maxZoom
  })), props.images && React.createElement("div", {
    className: `${classPrefix}-footer`
  }, (_c = props.renderFooter) === null || _c === void 0 ? void 0 : _c.call(props, props.images[index], index), React.createElement(SafeArea, {
    position: 'bottom'
  })));
  return renderToContainer(props.getContainer, node);
});